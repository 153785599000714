import React, {useEffect, useState} from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

import style from '../css/index.css'

import hero_1 from '../assets/images/hero/hero_1.jpg';
import hero_2 from '../assets/images/hero/hero_2.jpg';
import hero_3 from '../assets/images/hero/hero_3.jpg';
import hero_4 from '../assets/images/hero/hero_4.jpg';

import globe from '../assets/images/globe-solid.svg';
import handshake from '../assets/images/handshake-solid.svg';
import check from '../assets/images/check-solid.svg';

const Index = () => {
  return (
  <Layout>
    <section id="stats" className="py-20 lg:pb-20">
      <div className='container mx-auto '>
        <div className='text text-4xl font-semibold ml-4'>Kontakt</div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-8">
      <div className="container mx-auto text-center flex flex-col justify-center box-border px-4 md:px-0">
        <form
        action="https://formspree.io/f/xknegryk"
        method="POST"
        className="mx-auto text-center flex flex-col justify-center w-full max-w-md"
        >
        <div className="flex flex-col w-full text-left mb-4">
            <label>
                Vaše ime i prezime:
            </label>
            <input type="text" name="name"  className='mt-2 py-3 px-5'  style={{border: '1px solid gray'}}/>
        </div>
        <div className="flex flex-col w-full text-left mb-4">
            <label>
                Ime tvrtke:
            </label>
            <input type="text" name="company"  className='mt-2 py-3 px-5'  style={{border: '1px solid gray'}}/>
        </div>
        <div className="flex flex-col w-full text-left mb-4">
            <label>
                Vaš e-mail:
            </label>
            <input type="email" name="email"  className='mt-2 py-3 px-5'  style={{border: '1px solid gray'}}/>
        </div>
        <div className="flex flex-col w-full text-left">
            <label>
                Vaša poruka:
            </label>
            <textarea name="message" rows="8" className='mt-2 py-3 px-5' style={{border: '1px solid gray'}}></textarea>
        </div>
        <button type="submit" className='py-3 mt-6 bg-primary hover:bg-primary-darker text-white'>Pošalji</button>
        </form>
      </div>
    </section>
  </Layout>)
}

export default Index;
